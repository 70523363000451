<template>
    <div>
        <div class="user-bg">
            <img src="../../assets/图标-白-头像@2x.png" alt="">

            <div class="bottom-desc">
                <div class="desc-name">{{userInfo.userName}}</div>
                <div class="desc-grp">{{userInfo.officeName}}</div>
            </div>
        </div>

        <div class="round-box" style="text-align:left;margin-top: 20px;">
            <van-cell is-link size="large" :to="{path: '/me/userList'}">
                <template #title>
                    <img src="../../assets/图标-彩-班组@2x.png" alt="" class="cell-title-icon">
                    <span>调试班组</span>
                </template>
                <template #right-icon>
                    <!-- <van-badge :content="0" style="height:16px;"></van-badge> -->
                    <van-icon name="arrow" size="16" color="#969799"></van-icon>
                </template>
            </van-cell>
            <van-cell is-link size="large">
                <template #title>
                    <img src="../../assets/图标-彩-说明@2x.png" alt="" class="cell-title-icon">
                    <span>使用说明</span>
                </template>
            </van-cell>
        </div>
    </div>
</template>

<script>
import { mapGetters} from 'vuex'
export default {
    computed: {
        ...mapGetters(['userInfo'])
    }
    
}
</script>

<style lang="scss" scoped>
    .user-bg{
        height: 296px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../../assets/背景图@2x.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        img{
            width: 36px;
        }
        .bottom-desc{
            position: absolute;
            top: 50%;
            margin-top: 65px;
            left: 0;
            right: 0;
            .desc-name{
                color: #fff;
                font-size: 20px;
                margin-bottom: 5px;
            }
            .desc-grp{
                color: rgba(255,255,255,0.5);
                font-size: 14px;
            }
        }
    }
    .cell-title-icon{
        width: 22px;
        vertical-align: top;
        margin-right: 5px;
    }
</style>